<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { renderRichText, type ISbRichtext } from '@storyblok/svelte';

	export let text: ISbRichtext | string;
	export let className = '';

	export { className as class };
</script>

{#if text}
	<div class={classes('rich-text', className)}>
		{#if typeof text === 'string'}
			{text}
		{:else}
			{@html renderRichText(text)}
		{/if}
	</div>
{/if}
